import React from 'react'
import Navbar from 'components/navbar'
import Footer from 'components/footer'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'scss/abstract.scss'
import 'scss/gsap.scss'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

class Layout extends React.Component {

    componentDidMount() {
        // Inicializa ScrollTrigger
        gsap.registerPlugin(ScrollTrigger);
    
        // Configura ScrollTrigger
        ScrollTrigger.create({
          trigger: '#main', // Cambia esto al selector de tu contenedor principal
          start: 'top top',
          end: '+=500%', // Ajusta esto según el tamaño de tu contenido
          scrub: 1,
          snap: 1,
        });
      }
  

  render() {

    const { children } = this.props    
    

    return (
      <div id="main">
        <Navbar scroll={this.props.isHome ? true : false} sections={this.props.sections} />        
        <div>
        <div className="scroll-container">
          {children}
          </div>
          <Footer />
        </div>        
      </div>
    )
  }
}

export default Layout
